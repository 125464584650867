import React, { useState } from 'react'
import Navbar from "../../components/navbar/Navbar"
import './Contact.css'
import Footer from '../../components/footer/Footer'
import { addDoc, collection, Timestamp } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { NavLink } from 'react-router-dom';
import Loading from '../../components/loading/Loading';

const Contact = () => {

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    description: ''
  });
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {

      if (formData.name.trim() === '') {
        setSuccessMsg('')
        setErrorMsg('Name is required')
        return;
      }

      if (formData.name.length > 30 || formData.name.length < 2) {
        setSuccessMsg('')
        setErrorMsg('Name must be between 2 to 30 charaters long');
        return;
      }

      if (formData.email.trim() === '') {
        setSuccessMsg('')
        setErrorMsg('Email is required');
        return;
      }

      if (formData.phone.trim() === '') {
        setSuccessMsg('')
        setErrorMsg('Phone is required');
        return;
      }

      if (formData.description.trim() === '') {
        setSuccessMsg('')
        setErrorMsg("Description is required");
        return;
      }

      if (formData.description.length > 100 || formData.description.length < 2) {
        setSuccessMsg('')
        setErrorMsg('Description must be between 2 to 100 charaters long');
        return;
      }

      setLoading(true);
      await addDoc(collection(db, 'contacts'), {
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        description: formData.description,
        createdAt: Timestamp.now().toDate().toString(),
      });

      setFormData({
        name: '',
        email: '',
        phone: '',
        description: ''
      })
      setErrorMsg('');
      setSuccessMsg('Thanks for contacting us our team will be responding you soon.')
      setLoading(false);


    } catch (error) {
      console.log("error=>", error);
    }
  };

  return (
    <>
      {
        loading ? (<Loading />)
          :
          <>
            <Navbar />
            <div className='contact-heading'>
              Want to contact? Our support team is always out there to help.
            </div>

            <div className="container-contact">
              <div className="contact-box">
                <div className="contact-info">
                  <div className="contact-subheading">
                    Get in touch
                  </div>
                  <div className="contact-details">
                    <div className='info'><i class="fa-solid fa-envelope"></i>rustyscrapmarvelspvtltd@gmail.com</div>
                    <div className='info'><i class="fa-solid fa-phone"></i>+91 9340052032</div>
                    <div className='info'><i class="fa-solid fa-location-pin"></i>H No.6, Near Sulaimania School, Moti Masjid Bhopal - 462001</div>
                    <div className="social-links">
                      <NavLink to="https://instagram.com/rsm_pvt_ltd?igshid=MzRlODBiNWFlZA" target='_blank'><i class="fa-brands fa-instagram"></i></NavLink>
                      <NavLink to="https://www.facebook.com/profile.php?id=61551507291764&mibextid=cejktS" target='_blank'><i class="fa-brands fa-facebook"></i></NavLink>
                      <NavLink to="https://wa.me/+919340052032?text=Hello Rusty Scrap Marvels I'm interested in discussing business opportunities with you. Let's connect?" target='_blank'><i class="fa-brands fa-whatsapp"></i></NavLink>
                    </div>
                    {/* <p className='social-links'><i class="fa-brands fa-instagram"></i>Instagram</p>
              <p className='social-links'><i class="fa-brands fa-facebook"></i>Facebook</p>
              <p className='social-links'><i class="fa-brands fa-whatsapp"></i>Whatsapp</p> */}
                  </div>
                </div>
                <div className="contact-form">
                  <form onSubmit={handleSubmit}>
                    <div className="form-body">
                      <div className={errorMsg === '' ? '' : 'error-msg'}>{errorMsg}</div>
                      <div className={successMsg === '' ? '' : 'success-msg'}>{successMsg}</div>
                      <div className='form-grp'>
                        <label htmlFor="name">Name</label>
                        <input type="text" name="name" placeholder='Enter your name' value={formData.name} onChange={handleInputChange} required />
                      </div>

                      <div className='form-grp'>
                        <label htmlFor="email">Email</label>
                        <input type="email" name="email" placeholder='Enter your email' value={formData.email} onChange={handleInputChange} required />
                      </div>

                      <div className='form-grp'>
                        <label htmlFor="phone">Phone</label>
                        <input type="tel" name="phone" placeholder='Enter your phone' value={formData.phone} onChange={handleInputChange} required />
                      </div>

                      <div className='form-grp'>
                        <label htmlFor="description">Description</label>
                        <textarea name="description" id="" cols="20" rows="5" value={formData.description} onChange={handleInputChange}></textarea>
                      </div>

                      <div className='form-grp'>
                        <button type='submit' className='submit-btn'>Submit</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className="google-map-code">
            <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3665.6901659501027!2d77.39735769066503!3d23.254359193489513!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjPCsDE1JzE1LjYiTiA3N8KwMjMnNTkuNiJF!5e0!3m2!1sen!2sin!4v1696177500496!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title="This is a unique title" height="100%" width="100%" style={{borderRadius:"20px", border:"none"}}></iframe>
            </div>
            <Footer />
          </>
      }
    </>
  )
}

export default Contact