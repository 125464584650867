import React, { useState, useEffect } from 'react';
import Sidebar from '../../components/sidebar/Sidebar';
import './Dashboard.css';
import { db } from '../../config/firebase';
import {  collection, query, getCountFromServer } from 'firebase/firestore'

const Dashboard = () => {

  const [userCount, setUserCount] = useState("Processing...");
  const [categoryCount, setCategoryCount] = useState("Processing..");
  const [itemCount, setItemCount] = useState("Processing...");
  const [contactCount, setContactCount] = useState("Processing..");


  useEffect(() => {
    getCount()
  }, [])

  const getCount = async () => {

    try {

      const totalUsers = await getCountFromServer(query(collection(db, 'users')));
      const totalcategories = await getCountFromServer(query(collection(db, 'categories')));
      const totalItems = await getCountFromServer(query(collection(db, 'items')));
      const totalContacts = await getCountFromServer(query(collection(db, 'contacts')));

      setUserCount(totalUsers._data.count.integerValue);
      setCategoryCount(totalcategories._data.count.integerValue);
      setItemCount(totalItems._data.count.integerValue);
      setContactCount(totalContacts._data.count.integerValue);

    } catch (error) {
      console.log("error=>", error);
    }
  }

  return (
    <>
      <div className="panel-container">
        <Sidebar />
        <div className="panel-content">
          <div className="card-parent">
            <div className="card-style-dashboard">
              <h3>Total Users</h3>
              <h4 className='count-var'>{userCount}</h4>
            </div>
            <div className="card-style-dashboard">
              <h3>Total Categories</h3>
              <h4 className='count-var'>{categoryCount}</h4>
            </div>
            <div className="card-style-dashboard">
              <h3>Total Items</h3>
              <h4 className='count-var'>{itemCount}</h4>
            </div>
            <div className="card-style-dashboard">
              <h3>Total Contacts</h3>
              <h4 className='count-var'>{contactCount}</h4>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
