import React, { useState } from 'react';
import './AdminLogin.css';
import { useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from 'firebase/auth';
import { getDocs, collection, query, where } from 'firebase/firestore';
import { auth, db } from '../../config/firebase';
import Loading from '../../components/loading/Loading';


const AdminLogin = () => {
  // State for email and password
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");



  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // You can add your login logic here, using the 'email' and 'password' state variables
    try {
      // navigate("/admin/dashboard")

      setLoading(true);


      if (email.trim() === "") {
        setErrorMsg("Email is requried");
        setLoading(false)
        return;
      }

      if (password.trim() === "") {
        setErrorMsg("Password is requried");
        setLoading(false)
        return;
      }

      let res = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );

      if (res["operationType"] === "signIn") {
        let userUid = res.user.uid;
        const data = await getDocs(query(collection(db, 'admin'), where("uid", "==", userUid)));
        const filterData = data.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));

        setLoading(false);
        if (filterData.length === 1) {
          setErrorMsg("")
          setLoading(false)
          navigate("/admin/dashboard");
        } else {
          setErrorMsg("You are not admin")
          setLoading(false)
        }

      }
    } catch (error) {
      console.log("error=>", error);
      setErrorMsg(error.message)
      setLoading(false);
    }
  };

  return (
    <>
      {
        loading ? (<Loading />) : (

          <div className="page-container">
            <div className="login-container">
              <h2>Login</h2>
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <div className={errorMsg === '' ? '' : 'error-msg'}>{errorMsg}</div>
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={email}
                    onChange={handleEmailChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="password">Password</label>
                  <input
                    type="password"
                    id="password"
                    name="password"
                    value={password}
                    onChange={handlePasswordChange}
                  />
                </div>
                <button type="submit" className='admin-login-btn'>Login</button>
              </form>
            </div>
          </div>
        )
      }
    </>
  );
};

export default AdminLogin;
