import React, { useState, useEffect } from 'react'
import { NavLink, useNavigate } from "react-router-dom";
import './Sidebar.css';
import { onAuthStateChanged, signOut} from 'firebase/auth';
import { auth, db } from '../../config/firebase';

const Sidebar = () => {

  const [user, setUser] = useState();
  const navigate = useNavigate();


  useEffect(() => {

    const authVar = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser.displayName);
      } else {
        navigate("/admin/login")
      }
    });

    // Clean up the subscription when the component unmounts
    return () => authVar();
  }, []);


  const logOutHandler = async () => {

    try {
      // console.log("loggedOut");
      await signOut(auth);
      navigate("/admin/login")
    } catch (error) {
      console.log("error=>", error);
    }
  }

  return (
    <>
      <div className="sidebar-container">
        <div className='sidebar-heading'>Rusty Scrap Marvels</div>
        <div style={{color: "goldenrod"}}>Hello {user}</div>
        <div className='menu-container'>
          <NavLink to='/admin/dashboard' className="sidebar-item">Dashboard</NavLink>
          <NavLink to='/admin/users' className="sidebar-item">Users</NavLink>
          <NavLink to='/admin/categories' className="sidebar-item">Categories</NavLink>
          <NavLink to='/admin/items' className="sidebar-item">Items</NavLink>
          <NavLink to='/admin/contacts' className="sidebar-item">Contacts</NavLink>
        </div>
        <button className='admin-logout' onClick={logOutHandler}>Logout</button>
      </div>
    </>
  )
}

export default Sidebar