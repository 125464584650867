import React, { useState, useEffect } from 'react'
import Sidebar from '../../components/sidebar/Sidebar';
import { db } from '../../config/firebase';
import { doc, collection, query, orderBy, getDocs, deleteDoc } from 'firebase/firestore';
import Loading from '../../components/loading/Loading';

const AdminUserManagement = () => {

  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getUserList()
  }, []);

  const getUserList = async () => {
    try {

      setLoading(true);
      const data = await getDocs(query(collection(db, 'users'), orderBy("createdAt", "desc")));
      const filterData = data.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      setUsers(filterData);
      setLoading(false);

    } catch (error) {
      console.log("error=>", error);
    }
  }

  const deleteUser = async (id) => {

    try {

      setLoading(true);
      await deleteDoc(doc(db, "users", id))
      getUserList();
      
    } catch (error) {
      console.log("error=>", error);
      setLoading(false);
    }
  }

  return (
    loading ? (
      <Loading />
    ) : (
      <div className="panel-container">
        <Sidebar />
        <div className="panel-content">
          <div className="section-heading">
            User Management
          </div>
          {users.length > 0 ? (
            <div className="table-container">
              <table>
                <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Created At</th>
                  <th>Action</th>
                </tr>
                </thead>
                <tbody>
                  {users.map((user)=>{
                    return(
                      <tr key={user.id}>
                      <td>{user.name}</td>
                      <td>{user.email}</td>
                      <th>{user.phone}</th>
                      <td>{user.createdAt}</td>
                      <td><button className='delete-btn'  onClick={() => deleteUser(user.id)}>Delete</button></td>
                      </tr>
                    )
                  })}
                </tbody>
                {/* You can map over the users array and display each user's data here */}
              </table>
            </div>
          ) : (
            <div className='no-data'>
              <p>No users found</p>
            </div>
          )}
        </div>
      </div>
    )
  );
}
export default AdminUserManagement