import React, { useState } from 'react'
import './Navbar.css'
import { NavLink, useNavigate } from "react-router-dom";

const Navbar = () => {

    const [menuOpen, setMenuOpen] = useState(false);
    const navigate = useNavigate();

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };


    return (
        <>
            <nav className="navbar">
                <div className="logo">
                    <img src="/images/PNG.png" alt="logo" className='img-logo' onClick={() => navigate('/')}/>
                </div>
                <div className={`links ${menuOpen ? 'active' : ''}`}>
                    <NavLink to="/" className="anchor">Home</NavLink>
                    <NavLink to="/category" className="anchor">Category</NavLink>
                    <NavLink to="/contact" className="anchor">Contact</NavLink>
                </div>
                <div className="social-icons">
                    {/* Add your social icons here */}

                    <NavLink to="https://instagram.com/rsm_pvt_ltd?igshid=MzRlODBiNWFlZA" target='_blank'>
                        <i class="fa-brands fa-instagram"> </i>
                    </NavLink>
                    <NavLink to="https://www.facebook.com/profile.php?id=61551507291764&mibextid=cejktS" target='_blank'> <i class="fa-brands fa-facebook"> </i>
                    </NavLink>
                    <NavLink to="https://wa.me/+919340052032?text=Hello Rusty Scrap Marvels I'm interested in discussing business opportunities with you. Let's connect?" target='_blank'>  <i class="fa-brands fa-whatsapp"></i> </NavLink>
                </div>
                <div className={`hamburger ${menuOpen ? 'active' : ''}`} onClick={toggleMenu}>
                    <span className="bar"></span>
                    <span className="bar"></span>
                    <span className="bar"></span>
                </div>
            </nav>
        </>
    )
}

export default Navbar