import React, { useState, useEffect } from 'react'
import Sidebar from '../../components/sidebar/Sidebar';
import { db } from '../../config/firebase';
import {addDoc, doc, deleteDoc, collection, query, orderBy, getDocs, Timestamp } from 'firebase/firestore';
import Loading from '../../components/loading/Loading';

const AdminCategory = () => {

  const [category, setCategory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [createCategory, setCreateCategory] = useState({
    name: '',
    description: ''
  });

  useEffect(() => {
    getCategoryList()
  }, []);



  const getCategoryList = async () => {

    try {
      setLoading(true)
      const data = await getDocs(query(collection(db, 'categories'), orderBy("createdAt", "desc")));
      const filterData = data.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      setCategory(filterData);
      setLoading(false);

    } catch (error) {
      console.log("error=>", error);
      setLoading(false);
    }
  }

  async function deleteCategory(id) {

    try {

      setLoading(true);
      await deleteDoc(doc(db, "categories", id))
      getCategoryList();

    } catch (error) {
      console.log("error=>", error);
      setLoading(false);
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCreateCategory({ ...createCategory, [name]: value });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {

      setLoading(true);
      await addDoc(collection(db, 'categories'), {
        name: createCategory.name,
        description: createCategory.description,
        createdAt: Timestamp.now().toDate().toString()
      });
      setOpenModal(false);
      getCategoryList();      
      
    } catch (error) {
      console.log("error=>", error);
      setLoading(false);
    }
  }

  return (
    loading ? (
      <Loading />
    ) : (
      <div className="panel-container">
        <Sidebar />
        <div className="panel-content">
          <div className="section-heading">
            Category Management
          </div>

          <button onClick={() => {
            setOpenModal(true)
          }} className='create-btn'>Create Category</button>

          {
            openModal ?
              <div id="myModal" class="modal">
                <div class="modal-content">
                  <span class="close" onClick={() => {
                    setOpenModal(false)
                  }}>&times;</span>
                  <h3>Add Category</h3>
                  <form onSubmit={handleSubmit}>
                    <div className='form-grp'>
                      <label htmlFor="name">Name</label>
                      <input type="text" name="name" placeholder='Enter category name' value={createCategory.name} onChange={handleInputChange} required />
                    </div>
                    <div className='form-grp'>
                      <label htmlFor="name">Description</label>
                      <input type="text" name="description" placeholder='Enter description name' value={createCategory.description} onChange={handleInputChange} required />
                    </div>
                    <button type='submit' className='submit-btn'>Submit</button>
                  </form>

                </div>
              </div> : ""
          }


          {category.length > 0 ? (
            <div className="table-container">
              <table>
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Name</th>
                    <th>Description</th>
                    <th>Created At</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {category.map((cat) => {
                    return (
                      <tr key={cat.id}>
                        <td>{cat.id}</td>
                        <td>{cat.name}</td>
                        <td>{cat.description}</td>
                        <td>{cat.createdAt}</td>
                        <td><button className='delete-btn' onClick={() => deleteCategory(cat.id)}>Delete</button></td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <div className='no-data'>
              <p>No category found</p>
            </div>
          )}
        </div>
      </div>
    )
  )
}

export default AdminCategory