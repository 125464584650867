import React from 'react'
import { NavLink } from "react-router-dom";
import './Footer.css'

const Footer = () => {
    return (
        <>
      <footer>

      <section class="footer">
      <div class="footer-section footer-section-1">
        <h6 className='company-name'>Rusty Scrap Marvels</h6>
        <p>
        Rusty scrap marvels Pvt. Ltd is a leading company in the scrap market industry, dealing in items like copper, aluminum, steel, etc. 
        </p>
      </div>

      <div class="footer-section footer-section-2">
        <h6>Useful Links</h6>
        <p><NavLink to="/" className='footer-links'>Home</NavLink></p>
        <p><NavLink to="/category" className='footer-links'>Category</NavLink></p>
        <p><NavLink to="/contact" className='footer-links'>Contact</NavLink></p>
      </div>

      <div class="footer-section footer-section-3">
        <h6>Social links</h6>
        <p><NavLink to="https://www.facebook.com/profile.php?id=61551507291764&mibextid=cejktS" className='footer-links' target='_blank'>Facebook</NavLink></p>
        <p><NavLink to="https://instagram.com/rsm_pvt_ltd?igshid=MzRlODBiNWFlZA" className='footer-links' target='_blank'>Instagram</NavLink></p>
        <p><NavLink to="https://wa.me/+919340052032?text=Hello Rusty Scrap Marvels I'm interested in discussing business opportunities with you. Let's connect?" target='_blank' className='footer-links'>Whatsapp</NavLink></p>
      </div>

      <div class="footer-section footer-section-4">
        <h6>Contact</h6>
        <p><NavLink to="/" className='footer-links'>H No.6, Near Sulaimania School,<br/> Moti Masjid Bhopal - 462001</NavLink></p>
        <p><NavLink to="/" className='footer-links'>rustyscrapmarvelspvtltd@gmail.com</NavLink></p>
        <p><NavLink to="/" className='footer-links'>+91 9340052032</NavLink></p>
      </div>
    </section>
        <div className='copyright'>
          &copy; 2023 Copyright:
          Rusty Scrap Marvels Pvt. Ltd.
        </div>
      </footer>
        </>
    )
}

export default Footer