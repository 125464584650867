import React, { useState, useEffect } from 'react'
import Sidebar from '../../components/sidebar/Sidebar';
import { db } from '../../config/firebase';
import { doc, deleteDoc, collection, query, orderBy, getDocs } from 'firebase/firestore';
import Loading from '../../components/loading/Loading';

const AdminContacts = () => {

  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getContacts()
  }, []);

  const getContacts = async () => {

    try {
      setLoading(true)
      const data = await getDocs(query(collection(db, 'contacts')));
      const filterData = data.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      setContacts(filterData);
      setLoading(false);

    } catch (error) {
      console.log("error=>", error);
      setLoading(false);
    }
  }

  const deleteContact = async (id) => {

    try {

      setLoading(true);
      await deleteDoc(doc(db, "contacts", id))
      getContacts();
      
    } catch (error) {
      console.log("error=>", error);
      setLoading(false);
    }
  }

  return (
    loading ?(
       <Loading/> 
    ) : (
      <div className="panel-container">
         <Sidebar />
        <div className="panel-content">
          <div className="section-heading">
            Contact Management
          </div>
          {contacts.length > 0 ? (
            <div className="table-container">
              <table>
                <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Description</th>
                  <th>Created At</th>
                  <th>Action</th>
                </tr>
                </thead>
                <tbody>
                  {contacts.map((contact)=>{
                    return(
                      <tr key={contact.id}>
                      <td>{contact.name}</td>
                      <td>{contact.email}</td>
                      <th>{contact.phone}</th>
                      <th>{contact.description}</th>
                      <td>{contact.createdAt}</td>
                      <td><button className='delete-btn'  onClick={() => deleteContact(contact.id)}>Delete</button></td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <div className='no-data'>
              <p>No contacts found</p>
            </div>
          )}
          </div>
      </div>
    )
  )
}

export default AdminContacts