import React from 'react'
import Navbar from '../../components/navbar/Navbar'
import './Home.css'
import '../../index.css'
import Footer from '../../components/footer/Footer'
// import whyus1 from '/images/whyus1.jpg'

const Home = () => {

  return (
    <>
      <Navbar />

      {/** Welcome Screen */}
      <section className='banner'>
        <div className='heading'>Welcome, To <span className='golden-text'>Rusty</span> Scrap <span className='golden-text'>Marvels</span></div>
        <div className='quote'>

          {/* <span className='golden-text'>Just</span> because there's tarnish on the <span className='golden-text'>copper</span>, doesn't mean there's not a <span className='golden-text'>shine</span> beneath. */}

          <span className='golden-text'> Just </span> Scrap It ! 
          
          </div>
      </section>


      {/** ABOUT US */}
      <section className='about-us'>
        <div className="section-heading">
          About Us
        </div>
        <div className="container">
          <div className="about-us-row">
            <div className="about-us-image">
              <img src="/images/rsm_logo.jpeg" alt="abt-img" />
            </div>
            <div className="about-us-text">
              Rusty scrap marvels Pvt. Ltd was established in 2023 with an aim to provide quality scrap and import-export services globally. We are a leading company in the scrap market industry, dealing in items like copper, aluminum, steel, etc. We have a team of highly skilled and experienced professionals who are dedicated to providing the best services to our clients.
              We have a strong presence in the international market and have been successful in establishing long-term relationships with our clients.
              We believe in providing quality products and services at competitive prices.
              Our mission is to provide our customers with the best possible service and to build long-term relationships with our clients.
              <br />
              <br />
              We are aware of our responsibility to generate economic value for the Nation. In pursuit of our goals, we will
              make no compromise in complying with applicable laws and regulation at all levels.
            </div>
          </div>
        </div>
      </section>

      {/**  WHY US */}
      <section className='why-us'>
        <div className="section-heading">
          Why Us
        </div>
        <div className="container">
          <div className="why-us-row">
            <div className="why-us-image">
              <img src='/images/whyus1.webp' alt="img" height="350px" width="350px" />
            </div>
            <div className="why-us-text">
              <h1>Best Value</h1>
              <p>At our scrap buying platform, we're committed to delivering the best value to our customers. We understand the importance of quality scrap materials, and that's why we source the finest recyclables available. Our stringent quality control measures ensure that you're getting top-notch scrap materials at competitive prices. When you choose us, you're choosing quality and value, making us the best choice for sourcing scrap materials for your projects.</p>
            </div>
          </div>

          <div className="why-us-row">
            <div className="why-us-image">
              <img src='/images/whyus2.jpg' alt="img" height="350px" width="350px" />
            </div>
            <div className="why-us-text">
              <h1>Best Price</h1>
              <p>When it comes to purchasing scrap materials, we offer the best prices in the market. Our pricing structure is designed to provide you with cost-effective options for your specific needs. We believe that finding the right materials shouldn't break the bank, which is why we maintain competitive prices without compromising on quality. Trust us to deliver the best prices for your scrap material requirements.</p>
            </div>
          </div>

          <div className="why-us-row">
            <div className="why-us-image">
              <img src='/images/whyus3.jpg' alt="img" height="350px" width="350px" />
            </div>
            <div className="why-us-text">
              <h1>Best Services</h1>
              <p>At our scrap buying platform, we take pride in offering not only the best prices but also the best services. Our user-friendly interface makes it easy to browse, select, and purchase the scrap materials you need. Our dedicated customer support team is always available to assist you with any questions or concerns. We prioritize your convenience and satisfaction, making us the preferred choice for individuals and businesses looking for exceptional service while buying scrap materials.</p>
            </div>
          </div>
        </div>
      </section>

      {/* * Testimonials */}

      <section className="testimonials">
        <div className="section-heading">
          Testimonials
        </div>
        <div className="container">
          <div className="card-row">
            <div className="card-container">
              <div className="card-heading">
              Ankit Kumar
              </div>
              <div className="card-content">
                Their professionalism and dedication to their craft are commendable. They not only had what I needed but also offered it at unbeatable prices. Their transparency in pricing and the ease of conducting business is refreshing. I've found my trusted partner for sourcing scrap materials, and I can't thank  Rusty Scrap Marvels enough!
              </div>
            </div>
            <div className="card-container">
              <div className="card-heading">
                Alisha Patel
              </div>
              <div className="card-content">
                Rusty Scrap is amazing! I'm not a pro at this stuff, but I needed some stuff related to aluminium. The website was super easy to use, and I found what I needed. What blew me away were the prices. They're way lower than what I expected. Their customer service was so friendly and helpful, guiding me through the process. I'll definitely return for my next project. Rusty Scrap makes it easy and affordable.
              </div>
            </div>
            <div className="card-container">
              <div className="card-heading">
                Rk Prasad
              </div>
              <div className="card-content">
                Rusty Scrap Marvel is a gem! Their professionalism, vast inventory, and Aamirs' excellent support made my copper shopping experience a breeze. If you're looking for quality and affordability, look no further. Rusty scrap marvels has got you covered! Their customer support is good and they now how to maintain healthy trade relations.
              </div>
            </div>
            <div className="card-container">
              <div className="card-heading">
                Ali Ahmed
              </div>
              <div className="card-content">
                Their professionalism and dedication to their craft are commendable. The moment you step into their virtual store, you're met with an incredible inventory of scrap materials. What's truly impressive is their team's exceptional support. They patiently answered all my questions and helped me find the perfect materials for my project needs. If you're in the market for quality and affordability,Rusty Scrap Marvels is the place to go.
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  )
}

export default Home