import {Routes, Route} from 'react-router-dom';
import Home from "./pages/home/Home";
import Contact from "./pages/contact/Contact";
import Category from './pages/category/Category';
import Item from './pages/item/Item';
import AdminLogin from './pages/admin/AdminLogin';
import Dashboard from './pages/admin/Dashboard';
import AdminCategory from './pages/admin/AdminCategory';
import AdminCategoryItems from './pages/admin/AdminCategoryItems';
import AdminUserManagement from './pages/admin/AdminUserManagement';
import AdminContacts from './pages/admin/AdminContacts';
import SingleItem from './pages/item/SingleItem';
import Error from "./pages/error/Error"

function App() {
  return (
   <>
    <Routes>
      <Route path="/" element={<Home/>} ></Route>
      <Route path="/category" element={<Category/>} ></Route>
      <Route path="/contact" element={<Contact/>} ></Route>
      <Route path="/item" element={<Item/>}></Route>
      <Route path='/single-item' element={<SingleItem/>}></Route>
      <Route path='/admin/login' element={<AdminLogin/>}></Route>
      <Route path='/admin/dashboard' element={<Dashboard/>}></Route>
      <Route path='/admin/categories' element={<AdminCategory/>}></Route>
      <Route path='/admin/items' element={<AdminCategoryItems/>}></Route>
      <Route path='/admin/users' element={<AdminUserManagement/>}></Route>
      <Route path='/admin/contacts' element={<AdminContacts/>}></Route>
      <Route path='*' element={<Error/>}></Route>
    </Routes>
   </>
  );
}

export default App;
