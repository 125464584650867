import { initializeApp } from "firebase/app";
import {getFirestore} from "firebase/firestore"
import {getStorage} from "firebase/storage"
import {getAuth} from "firebase/auth"

// Your web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: "AIzaSyA3AR1rCe2ZKeL7g5RJ6nH9S_ZrxmpKGfE",
//   authDomain: "rusty-app-44533.firebaseapp.com",
//   projectId: "rusty-app-44533",
//   storageBucket: "rusty-app-44533.appspot.com",
//   messagingSenderId: "470378665374",
//   appId: "1:470378665374:web:e6372b3f3d7f31eb9bf28f"
// };

const firebaseConfig = {
  apiKey: "AIzaSyCgI9dms6BOIwsoeOQ8ezO1bo81U_Dr7Fc",
  authDomain: "rusty-v1.firebaseapp.com",
  projectId: "rusty-v1",
  storageBucket: "rusty-v1.appspot.com",
  messagingSenderId: "321764032992",
  appId: "1:321764032992:web:5d965de437a87ef2652b85",
  measurementId: "G-6Z39Q67ZL5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const storage = getStorage(app);
export const auth = getAuth(app);
