import React from 'react';
import './Error.css';
import { useNavigate } from "react-router-dom";


const Error = () => {

  const navigate = useNavigate();


  return (
    <div className="error-container">
      <div className="error-content">
      <h2>Oops Error 404! Page not found.</h2>
      <p>The page you are looking for might not exist or has been moved.</p>
      <button className="error-button" onClick={() => navigate("/")}>Go to Home</button>
      </div>
    </div>
  );
};

export default Error;
