import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import Navbar from '../../components/navbar/Navbar'
import Footer from '../../components/footer/Footer'
import Loading from '../../components/loading/Loading';
import './Item.css'
import { db } from "../../config/firebase";
import { getDocs, collection, orderBy, query, where } from 'firebase/firestore'
import { useNavigate } from "react-router-dom";


const Item = () => {

  const [item, setitem] = useState([]);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const categoryId = queryParams.get('category_id');

  const navigate = useNavigate();


  const itemsCollectionRef = collection(db, 'items')

  const itemQuery = query(itemsCollectionRef, where("categoryId", "==", categoryId))

  useEffect(() => {
    getItemList()
  }, []);

  const getItemList = async () => {
    try {


      const data = await getDocs(itemQuery);
      const filterData = data.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      setitem(filterData);
      setLoading(false);

    } catch (error) {
      console.log("error=>", error);
    }
  }

  return (
    loading ? (
      <Loading />
    ) : (
      <>
        <Navbar />
        <div className="container">
          {item.length > 0 ?
            (<div className="card-parent">

              {item.map((data) => (
                <div className='card-style-item' key={data.id}>
                  <h2>{data.name}</h2>
                  <img src={`${data?.image}`} alt="image" />
                  <p>{data.price}</p>
                  <button className='button-style' onClick={() => {
                    navigate(`/single-item?item_id=${data.id}&&category_id=${categoryId}`)
                  }}>Explore {data.name}</button>
                </div>
              ))}
            </div>) : (
              <div className='no-data'>
                <p>Error 404</p>
                <p>Oops! No item available for now</p>
              </div>
            )
          }
        </div>
        <Footer />
      </>
    )
  )
}

export default Item