import React, { useState, useEffect } from 'react'
import Navbar from '../../components/navbar/Navbar'
import Footer from '../../components/footer/Footer'
import Loading from '../../components/loading/Loading';
import './Category.css'
import { db } from "../../config/firebase";
import { getDocs, collection, orderBy, query } from 'firebase/firestore'
import { useNavigate } from "react-router-dom";

const Category = () => {

    const [category, setCategory] = useState([]);
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();

    const categoriesCollectionRef = collection(db, 'categories')

    const categoryQuery = query(categoriesCollectionRef, orderBy("name")) 

    useEffect(() => {
        getCategoryList()
    }, []);

    const getCategoryList = async () => {
        try {


            const data = await getDocs(categoryQuery);
            const filterData = data.docs.map((doc) => ({
                ...doc.data(),
                id: doc.id,
            }));
            
            
            setCategory(filterData);
            setLoading(false);

        } catch (error) {
            console.log("error=>", error);
        }
    }

    const fetchItem = (id) => {
        console.log("hello", id);
        navigate(`/item?category_id=${id}`)
    }

    return (
        loading ? (
          <Loading />
        ) : (
          <>
            <Navbar />
            <div className="container">
                { category.length > 0 ?
             ( <div className="card-parent">
                
                {category.map((data) => (
                  <div className='card-style' key={data.id}>
                    <h2>{data.name}</h2>
                    <p>{data.description}</p>
                    <button className='button-style' onClick={() => fetchItem(data.id)}>Explore {data.name}</button>
                  </div>
                ))}
              </div>) : (
                <div className='no-data'>
                    <p>Error 404</p>
                    <p>Oops! No data available for now</p>
                </div>
              )
}
            </div>
            <Footer />
          </>
        )
      );
      
}

export default Category