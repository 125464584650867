import React, { useState, useEffect } from 'react'
import Sidebar from '../../components/sidebar/Sidebar';
import { db, storage } from '../../config/firebase';
import { doc, addDoc, collection, deleteDoc, query, orderBy, getDocs, Timestamp } from 'firebase/firestore';
import Loading from '../../components/loading/Loading';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const AdminCategoryItems = () => {

  const [items, setItems] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [createCategoryItem, setCreateCategoryItem] = useState({
    name: '',
    description: '',
    price: '',
    categoryId: ''
  });


  useEffect(() => {
    fetchItems();
    fetchCategories()
  }, []);



  const fetchItems = async () => {

    try {
      setLoading(true);
      const data = await getDocs(query(collection(db, 'items')));
      const filterData = data.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      setItems(filterData);
      setLoading(false);

    } catch (error) {
      console.log("error=>", error);
      setLoading(false);
    }
  }

  const fetchCategories = async () => {

    try {
      const data = await getDocs(query(collection(db, 'categories'), orderBy("createdAt", "desc")));
      const filterData = data.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      setCategoryOptions(filterData);

    } catch (error) {
      console.log("error=>", error);
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCreateCategoryItem({ ...createCategoryItem, [name]: value });
  };

  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {

      if (!selectedFile) {
        alert("File is required");
        return;
      }
      console.log("createcat", createCategoryItem);
      if(createCategoryItem.categoryId === null || createCategoryItem.categoryId === "") {
        alert("Choose a valid category");
        return;
      }
      
      let imageUrl = null;
      const storageRef = ref(storage, `images/${selectedFile.name}`); //make ref
      await uploadBytes(storageRef, selectedFile); // put in storage
      const snapshot = await getDownloadURL(storageRef); //get download url
      imageUrl = snapshot;
    

      setLoading(true);
      await addDoc(collection(db, 'items'), {
        name: createCategoryItem.name,
        description: createCategoryItem.description,
        price: createCategoryItem.price,
        categoryId: createCategoryItem.categoryId,
        image: imageUrl,
        createdAt: Timestamp.now().toDate().toString()
      });
      setOpenModal(false);
      fetchItems();

    } catch (error) {
      console.log("error=>", error);
      setLoading(false);
    }
  }

  const deleteCategoryItem = async (id) => {

    try {
      setLoading(true);
      await deleteDoc(doc(db, "items", id))
      fetchItems();

    } catch (error) {
      console.log("error=>", error);
      setLoading(false);
    }
  }

  return (
    loading ? (
      <Loading />
    ) : (
      <div className="panel-container">
        <Sidebar />
        <div className="panel-content">
          <div className="section-heading">
            Category Items Management
          </div>

          <button onClick={() => {
            setOpenModal(true)
          }} className='create-btn'>Create Category Items</button>
 
          {
            openModal ?
              <div id="myModal" class="modal">
                <div class="modal-content">
                  <span class="close" onClick={() => {
                    setOpenModal(false)
                  }}>&times;</span>
                  <h3>Add Category</h3>
                  <form onSubmit={handleSubmit}>
                    <div className='form-grp'>
                      <label htmlFor="name">Name</label>
                      <input type="text" name="name" placeholder='Enter item name' value={createCategoryItem.name} onChange={handleInputChange} required />
                    </div>
                    <div className='form-grp'>
                      <label htmlFor="categoryId">Category</label>
                      <select name="categoryId" onChange={handleInputChange} required>
                        {categoryOptions.length > 0 ? (
                          <>
                            <option value={null}>Choose category</option>
                            {categoryOptions.map((option, index) => (
                              <option key={index} value={option.id}>
                                {option.name}
                              </option>
                            ))}
                          </>
                        ) : (
                          <option>No categories available</option>
                        )}
                      </select>
                    </div>
                    <div className='form-grp'>
                      <label htmlFor="image">Image</label>
                      <input type="file" name="image" onChange={handleFileSelect} accept=".jpg, .jpeg, .png, .gif" />
                    </div>
                    <div className='form-grp'>
                      <label htmlFor="price">Price</label>
                      <input type="text" name="price" placeholder='Enter price' value={createCategoryItem.price} onChange={handleInputChange} required />
                    </div>

                    <div className='form-grp'>
                      <label htmlFor="description">Description</label>
                      <input type="text" name="description" placeholder='Enter description' value={createCategoryItem.description} onChange={handleInputChange} required />
                    </div>
                    <button type='submit' className='submit-btn'>Submit</button>
                  </form>
                </div>
              </div> : ""
          }


          {items.length > 0 ? (
            <div className="table-container">
              <table>
                <thead>
                  <tr>
                    <th>Item Id</th>
                    <th>Name</th>
                    <th>Category Id</th>
                    <th>Description</th>
                    <th>Image</th>
                    <th>Price</th>
                    <th>Created At</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {items.map((item) => {
                    return (
                      <tr key={item.id}>
                        <td>{item.id}</td>
                        <td>{item.name}</td>
                        <td>{item.categoryId}</td>
                        <td>{item.description}</td>
                        <td> <img src={item.image} alt="some-img" className='table-image' /></td>
                        <td>{item.price}</td>
                        <td>{item.createdAt}</td>
                        <td><button className='delete-btn'  onClick={() => deleteCategoryItem(item.id)}> Delete</button></td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <div className='no-data'>
              <p>No item found</p>
            </div>
          )}
        </div>
      </div>
    )
  )
}

export default AdminCategoryItems