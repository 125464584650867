import React, { useState, useEffect } from 'react';
import Navbar from '../../components/navbar/Navbar';
import { useLocation } from 'react-router-dom';
import './SingleItem.css';
import Footer from '../../components/footer/Footer';
import {
    createUserWithEmailAndPassword,
    onAuthStateChanged,
    signOut,
    signInWithEmailAndPassword,
    updateProfile,
} from 'firebase/auth';
import { addDoc, doc, getDoc, collection, Timestamp } from 'firebase/firestore';
import { auth, db } from '../../config/firebase';
import Loading from '../../components/loading/Loading';

const SingleItem = () => {

    const [isSignUp, setIsSignUp] = useState(false);
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const itemId = queryParams.get('item_id');
    const [itemData, setItemData] = useState(null);


    const [registerUserData, setRegisterUserData] = useState({
        name: '',
        email: '',
        password: '',
        phone: '',
    });

    const [loginUserData, setLoginUserData] = useState({
        email: '',
        password: '',
    });

    const [user, setUser] = useState(null);
    const [errorMsg, setErrorMsg] = useState("");


    useEffect(() => {

        getItemList();
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            if (currentUser) {
                setUser(currentUser);
            } else {
                setUser(null);
            }
        });

        // Clean up the subscription when the component unmounts
        return () => unsubscribe();
    }, []);

    const handleSignIn = async () => {
        try {

            setLoading(true);


            if (loginUserData.email.trim() === "") {
                setErrorMsg("Email is requried");
                setLoading(false)
                return;
            }

            if (loginUserData.password.trim() === "") {
                setErrorMsg("Password is requried");
                setLoading(false)
                return;
            }

            let res = await signInWithEmailAndPassword(
                auth,
                loginUserData.email,
                loginUserData.password
            );

            if (res["operationType"] === "signIn") {
                setErrorMsg("");
                setLoading(false);
            }

        } catch (error) {
            console.log('errorinlogin', error.message);
            setErrorMsg(error.message);
            setLoading(false);
        }
    };

    const handleSignUp = async () => {
        try {

            setLoading(true);

            if (registerUserData.name.trim() === "") {
                setErrorMsg("Name is requried");
                setLoading(false)
                return;
            }

            if (registerUserData.name.length < 2 || registerUserData.name.length > 40) {
                setErrorMsg("Name length must be between 2 to 40 characters");
                setLoading(false)
                return;
            }

            if (registerUserData.email.trim() === "") {
                setErrorMsg("Email is requried");
                setLoading(false)
                return;
            }

            if (registerUserData.password.trim() === "") {
                setErrorMsg("Password is requried");
                setLoading(false)
                return;
            }

            if (registerUserData.password < 6 || registerUserData.password > 12) {
                setErrorMsg("Password length must be between 6 to 12 characters");
                setLoading(false)
                return;
            }

            if (registerUserData.phone.trim() === "") {
                setErrorMsg("Phone is requried");
                setLoading(false)
                return;
            }

            let res = await createUserWithEmailAndPassword(
                auth,
                registerUserData.email,
                registerUserData.password
            );

            if (res["operationType"] === "signIn") {
                let user = res.user;
                await updateProfile(user, {
                    displayName: registerUserData.name,
                });

                await addDoc(collection(db, 'users'), {
                    name: registerUserData.name,
                    email: registerUserData.email,
                    phone: registerUserData.phone,
                    uid: user.uid,
                    createdAt: Timestamp.now().toDate().toString(),
                });

                setRegisterUserData({
                    name: '',
                    email: '',
                    password: '',
                    phone: '',
                })
                setErrorMsg("");
                setLoading(false);
            }

        } catch (error) {
            console.log('errorhai=>', error.message);
            setErrorMsg(error.message)
            setLoading(false);
        }
    };

    const handleInputChange = (e, isSignUp) => {
        const { name, value } = e.target;
        if (isSignUp) {
            setRegisterUserData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        } else {
            setLoginUserData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };

    const getItemList = async () => {
        try {


            setLoading(true);
            const snap = await getDoc(doc(db, 'items', itemId))

            if (snap.exists()) {
                console.log(snap.data())
                setItemData(snap.data());
                setLoading(false);
            }
            else {
                console.log("No such document")
                setLoading(false);
            }

        } catch (error) {
            console.log("error=>", error);
            setLoading(false);
        }
    }

    const handleLogOut = async () => {

        try {
            setErrorMsg("")
            setLoading(true);
            await signOut(auth);
            setLoading(false)
        } catch (error) {
            console.log('error=>', error);
            setLoading(false)
        }
    };

    
    const handlePurchase = () => {
      const itemName = itemData?.name;
      const itemPrice = itemData?.price;
      const itemDescription = itemData?.description;
   
      // Construct the WhatsApp message
      const message = `Hi Rusty Scrap Marvels, I am interested in purchasing:%0AName: ${itemName}%0APrice: ${itemPrice}%0ADescription: ${itemDescription}`;
  
  
      // Redirect to WhatsApp
      window.open(`https://wa.me/+919340052032?text=${message}`, '_blank');
    };

    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <>
                    <Navbar />
                    {!user ? (
                        <div className="auth-page">
                            <div className="auth-tabs">
                                <div
                                    className={`auth-tab ${isSignUp ? 'active' : ''}`}
                                    onClick={() => {
                                        setIsSignUp(false);
                                        setErrorMsg("");
                                    }}
                                >

                                    Sign In
                                </div>
                                <div
                                    className={`auth-tab ${!isSignUp ? 'active' : ''}`}
                                    onClick={() => {
                                        setIsSignUp(true);
                                        setErrorMsg("");
                                    }}
                                >
                                    Sign Up
                                </div>
                            </div>
                            <form className="auth-form">
                                <div className={errorMsg === '' ? '' : 'error-msg'}>{errorMsg}</div>
                                {isSignUp && (
                                    <div>
                                        <label htmlFor="name">Name</label>
                                        <input
                                            type="text"
                                            id="name"
                                            name="name"
                                            value={registerUserData.name}
                                            onChange={(e) => handleInputChange(e, true)}
                                            className='auth-input'
                                        />
                                    </div>
                                )}
                                <div>
                                    <label htmlFor="email">Email</label>
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        value={isSignUp ? registerUserData.email : loginUserData.email}
                                        onChange={(e) => handleInputChange(e, isSignUp)}
                                        className='auth-input'
                                    />
                                </div>
                                <div>
                                    <label htmlFor="password">Password</label>
                                    <input
                                        type="password"
                                        id="password"
                                        name="password"
                                        value={isSignUp ? registerUserData.password : loginUserData.password}
                                        onChange={(e) => handleInputChange(e, isSignUp)}
                                        className='auth-input'
                                    />
                                </div>
                                {isSignUp && (
                                    <div>
                                        <label htmlFor="name">Phone</label>
                                        <input
                                            type="text"
                                            id="phone"
                                            name="phone"
                                            value={registerUserData.phone}
                                            onChange={(e) => handleInputChange(e, true)}
                                            className='auth-input'
                                        />
                                    </div>
                                )}
                                <button
                                    type="button"
                                    onClick={isSignUp ? handleSignUp : handleSignIn}
                                    className="auth-button"
                                >
                                    {isSignUp ? 'Sign Up' : 'Sign In'}
                                </button>
                            </form>
                        </div>
                    ) : (
                        <>
                            <div className="container">
                            <div class='user-greet'>
                                <p>Hello, {user.displayName}</p>
                                <button onClick={handleLogOut} className='log-out-btn'>Logout</button>
                            </div>

                            <div className="single-item-container">
                                <div className="single-item-image">
                                    <img src={`${itemData?.image}`} alt="image" />
                                </div>
                                <div className="single-item-details">
                                    <p>Name: {itemData?.name}</p>
                                    <p>Price: {itemData?.price}</p>
                                    <p>Description: {itemData?.description}</p>
                                    <button className='purchase-btn' onClick={handlePurchase}><i class="fa-brands fa-whatsapp" style={{marginRight:"6px"}}></i>Purchase</button>
                                </div>
                            </div>
                            </div>
                        </>
                    )}
                    <Footer />
                </>
            )}
        </>
    );

};

export default SingleItem;
